export const CHANGE_VIEW_MODE = "CHANGE_VIEW_MODE";

export const changeViewMode = (mode) => ({
  type: CHANGE_VIEW_MODE,
  payload: mode,
});

export const SET_WINDOW_WIDTH = "SET_WINDOW_WIDTH";

export const setWindowWidth = (width) => ({
  type: SET_WINDOW_WIDTH,
  payload: width,
});
