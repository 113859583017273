import React, { ReactElement, ChangeEvent, useState } from "react";
import { sanitize, maskCost } from "../../helpers";
import { maskCarNumber, maskPhoneNumber } from "../../helpers";
import MyDivide from "../MyDivide";
/*
 * Интерфейс входных данных
 */
interface IProps {
  title?: string;
  select?: boolean;
  carNumber?: boolean;
  phoneNumber?: boolean;
  cost?: boolean;
  milleage?: boolean;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}
/*
 * Кастомный компонент select
 */
function MyInput({
  title = "",
  select = false,
  carNumber = false,
  phoneNumber = false,
  cost = false,
  milleage = false,
  placeholder = "",
  value,
  onChange,
}: IProps): ReactElement {
  const [isHovered, setIsHovered] = useState(false);
  // Обработчик изменений в поле ввода
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Получаем значение
    const val = sanitize(e.target.value);
    // Проверяем условия
    if (carNumber) {
      onChange(maskCarNumber(val));
    } else if (phoneNumber) {
      onChange(maskPhoneNumber(val));
    } else if (cost || milleage) {
      onChange(maskCost(val));
    } else {
      onChange(val);
    }
  };
  // Возвращаем шаблон
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {title && <MyDivide title={title} left hovered={isHovered} />}

      <input
        style={{
          cursor: select ? "pointer" : "",
          borderColor: isHovered ? "var(--primary)" : "var(--gray)",
          width: "100%",
          backgroundColor: "var(--white)",
          appearance: "none",
          border: "var(--border)",
          padding: "var(--paddingSmall) var(--paddingBase)",
          borderRadius: "var(--borderRadius)",
        }}
        readOnly={select}
        value={value}
        onChange={handleChange}
        placeholder={placeholder || title}
      />
    </div>
  );
}
/*
 * Экспорт
 */
export default MyInput;
