import React, { memo } from "react";
import Navbar from "../Navbar";
import Logo from "../MyLogo";
import MyFlex from "../MyFlex";
/*
 * Объект стилей
 */
const styles = {
  wrap: {
    width: "100%",
    backgroundColor: "var(--white)",
    top: 0,
    zIndex: 1,
  },
  header: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    padding: "var(--paddingBase) 0",
    display: "flex",
    gap: "var(--gap)",
  },
};
/*
 * Компонент шапки приложения
 */
const Header = () => {
  // Возвращаем шаблон
  return (
    <div style={{ position: "sticky", ...styles.wrap }}>
      <header
        style={{ ...styles.header, flexDirection: "column" }}
        id="isHeader"
      >
        <div className="container">
          <MyFlex between>
            <Logo />

            <Navbar />
          </MyFlex>
        </div>
      </header>
    </div>
  );
};
/*
 * Экспорт
 */
export default memo(Header);
