import React, { ReactElement } from "react";
import { Modal } from "antd";
/*
 * Интерфейс входных данных
 */
interface IProps {
  children: any;
  onCancel: Function;
}
/*
 * Объект стилей
 */
const styles = {
  inner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "var(--gap)",
  },
};
/*
 * Компонент для отображения файла
 */
export const MyModal = ({ children, onCancel }: IProps): ReactElement => {
  // Возвращаем шаблон
  return (
    <Modal
      centered
      width={"max-content"}
      open={true}
      onCancel={() => onCancel()}
    >
      <div
        style={{
          ...styles.inner,
          flexDirection: "column",
        }}
      >
        {children}
      </div>
    </Modal>
  );
};
