import React, { memo, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import MyFlex from "../MyFlex";
import { generateImagePath } from "../../helpers";
import plugCar from "../../assets/plug_car.png";
import plugObject from "../../assets/plug_object.png";
import ImageViewer from "../ImageViewer";
import MyEllipsisRow from "../MyEllipsisRow";
import { IObject } from "../../types/base_object";
/*
 * Компонент карточки объекта/машины
 */
const MyCard = ({ object }: { object: IObject }): ReactElement => {
  // State названия карточки
  const [name, setName] = useState<string>(
    `${object?.brand || object?.name || ""}`,
  );
  // State названия изображения карточки
  const [imgName, setImgName] = useState<string>(
    `${object?.image?.name?.new || ""}`,
  );
  // State оригинального названия изображения карточки
  const [imgOldName, setImgOldName] = useState<string>(
    `${object?.image?.name?.old || ""}`,
  );
  // Объект навигации
  const navigate = useNavigate();
  // Функция перехода на внутреннею страницу объекта
  const toInsidePage = () => {
    // Перенаправление
    navigate(`/inside/${object?.id}`);
  };
  // Тип объекта
  const type = object.type;
  // Получение пути к изображению для карточки
  const getImagePath = () => {
    // Если есть название картинки
    if (imgName) {
      // Генерация пути к картинке на сервере
      return generateImagePath(imgName);
    } else {
      // Если карточка для объекта
      if (type === "object") {
        // Возвращаем заглушку для объекта
        return plugObject;
      }
      // Если карточка для объекта
      if (type === "car") {
        // Возвращаем заглушку для машины
        return plugCar;
      }
      // Возвращаем пустую строку
      return "";
    }
  };
  // Возвращаем шаблон
  return (
    <div
      style={{
        border: "var(--border)",
        borderRadius: "var(--borderRadius)",
        padding: "var(--paddingBase)",
        cursor: "pointer",
      }}
      className="my-card"
      onClick={toInsidePage}
      title={name}
    >
      <MyFlex vertical width="100%" height="7rem">
        <ImageViewer src={getImagePath()} alt={imgOldName} single={true} />

        <MyEllipsisRow text={name} />
      </MyFlex>
    </div>
  );
};
/*
 * Экспортируем
 */
export default memo(MyCard);
