import React, { useState, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { PlusCircleFilled, DeleteFilled, EditFilled } from "@ant-design/icons";
import CreateDetail from "../Forms/Create/Detail";
import EditObject from "../Forms/Edit";
import { myFetch } from "../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../helpers/notify";
import { IObject } from "../../types/base_object";
import MyButton from "../MyButton";
import MyFlex from "../MyFlex";
import MyPopconfirm from "../MyPopconfirm";
/*
 * Интерфейс входных данных
 */
interface IProps {
  getData: Function;
  isData: IObject;
  id: String;
}
/*
 * Компонент кнопок внутренней страницы
 */
export default function ({ getData, isData, id }: IProps): ReactElement {
  // Статус загрузки
  const [loading, setLoading] = useState(false);
  // Статус модального окна
  const [modal, setModal] = useState(false);
  // Флаг указывающий какое модальное окно открыть
  const [type, setType] = useState("");
  // Уникальный идентификатор модального окна
  const [key, setKey] = useState(Date.now());
  // Объект навигатора
  const navigate = useNavigate();
  // Функция удаления объекта
  const del = async () => {
    // Меняем статус загрузки
    setLoading(true);
    // Основная логика
    try {
      // Делаем запросы получаем ответ сервера
      const { data, error } = await myFetch(id, {}, "DELETE");
      // Показываем ошибку
      if (error) return showErrorNotify(error);
      // Показываем сообщение
      showSuccessNotify(data);
      // Переадресация на главную страницу
      navigate("/");
    } catch ({ message }: any) {
      // console.log(message);
    } finally {
      // Меняем статус загрузки
      setLoading(false);
    }
  };
  // Закрываем всё
  const toClose = async () => {
    // Закрываем модальное окно
    setModal(false);
    // Добавляем ключ для модального окна
    setKey(Date.now());
  };
  // Возвращаем шаблон
  return (
    <>
      <MyFlex width="max-content">
        <MyButton
          alt="Редактировать объект"
          click={() => {
            // Добавляем тип открываемой модалки
            setType("edit");
            // Добавляем статус для открытия модалки
            setModal(true);
          }}
          icon={<EditFilled />}
          primary
        />

        <MyButton
          alt="Создать деталь"
          click={() => {
            // Добавляем тип открываемой модалки
            setType("create");
            // Добавляем статус для открытия модалки
            setModal(true);
          }}
          icon={<PlusCircleFilled />}
          green
        />

        <MyPopconfirm title="Удалить объект?" onConfirm={() => del()} right>
          <MyButton
            alt="Удалить объект"
            icon={<DeleteFilled />}
            loading={loading}
            red
          />
        </MyPopconfirm>
      </MyFlex>

      <Modal open={modal} onCancel={toClose}>
        {type === "create" && (
          <CreateDetail
            getData={getData}
            toClose={toClose}
            isData={isData}
            key={key}
          />
        )}

        {type === "edit" && (
          <EditObject
            getData={getData}
            toClose={toClose}
            isData={isData}
            key={key}
          />
        )}
      </Modal>
    </>
  );
}
