import React, { useState, useMemo, useEffect } from "react";
import { Form } from "antd";
import { getBrands, getYears } from "../../../helpers";
import { myFetch } from "../../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../../helpers/notify";
import { IForm } from "../../../types/props";
import MySelect from "../../MySelect";
import MyInput from "../../MyInput";
import MyButton from "../../MyButton";
import MyFlex from "../../MyFlex";
import MySpiner from "../../MySpiner";
/*
 * Компонент создания нового объекта
 */
export default function ({ getData, toClose }: IForm) {
  // State с типамами объектов
  const [types, setTypes] = useState([]);
  // State с типом объекта
  const [type, setType] = useState("");
  // State с названием объекта
  const [name, setName] = useState("");
  // State с описанием объекта
  const [description, setDescription] = useState("");
  // State с маркой автомобиля
  const [brand, setBrand] = useState("");
  // State с годом производства автомобиля
  const [year, setYear] = useState("");
  // State с моделью автомобиля
  const [model, setModel] = useState("");
  // State с г/н автомобиля
  const [number, setNumber] = useState("");
  // State с статусом загрузки формы
  const [loading, setLoading] = useState(false);
  // Действия при инициализации компонента
  useEffect(() => {
    // Запрос типов
    getTypes();
  }, []);
  // Очистка всех полей
  const allClear = () => {
    // Очищаем марку автомобиля
    setBrand("");
    // Очищаем год производства автомобиля
    setYear("");
    // Очищаем модель автомобиля
    setModel("");
    // Очищаем г/н автомобиля
    setNumber("");
  };
  // Функция создания объекта
  const getTypes = async () => {
    // Основная логика
    try {
      // Делаем запрос и получаем ответ с сервера
      const { data, error } = await myFetch("types");
      // Показываем ошибку
      if (error) return showErrorNotify(error);
      // Добавление данных
      setTypes(data);
    } catch ({ message }: any) {
      // console.log(message)
    }
  };
  // Определение активности кнопки для формы
  const isDisabled = useMemo((): boolean => {
    if (type === "object") {
      // Возвращаем boolean значение
      return !name;
    } else {
      // Возвращаем boolean значение
      return !brand || !year || !model || number.length < 8;
    }
  }, [brand, year, model, number, name]);
  // Функция создания объекта
  const isCreate = async () => {
    // Ставим статус загрузки
    setLoading(true);
    // Основная логика
    try {
      // Собираем полезную нагрузку
      let payload;
      // Проверяем условия
      if (brand && year && model && number) {
        payload = {
          brand,
          model,
          year,
          number,
        };
      } else if (name) {
        payload = { name, description };
      }
      payload = { ...payload, type };
      // Делаем запрос и получаем ответ с сервера
      const { data, error } = await myFetch("", payload, "POST");
      // Показываем ошибку
      if (error) return showErrorNotify(error);
      // Показываем сообщение
      showSuccessNotify(data);
      // Запрашиваем список объектов
      await getData();
      // Закрываем модальное окно
      toClose(false);
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Очистка всех полей
      allClear();
      // Обнуляем статус загрузки
      setLoading(false);
    }
  };
  // Если ещё загрузились не все типы
  if (!types.length) {
    return (
      <MyFlex center height="100%">
        <MySpiner big />
      </MyFlex>
    );
  }
  // Возвращаем шаблон
  return (
    <Form style={{ minWidth: "20rem" }} onFinish={isCreate}>
      <MyFlex vertical>
        <MySelect
          options={types}
          title="Тип объекта"
          placeholder="Выберите тип объекта"
          value={type}
          onChange={setType}
        />

        {type === "object" && (
          <>
            <MyInput
              title={"Название"}
              value={name}
              onChange={setName}
              placeholder="Введите название"
            />

            <MyInput
              title={"Описание"}
              value={description}
              onChange={setDescription}
              placeholder="Введите описание"
            />
          </>
        )}

        {type === "car" && (
          <>
            <MySelect
              options={getBrands()}
              title={"Марка автомобиля"}
              placeholder="Выберите марку автомобиля"
              value={brand}
              onChange={setBrand}
            />

            <MyInput
              title={"Модель автомобиля"}
              value={model}
              onChange={setModel}
              placeholder="Введите модель автомобиля"
            />

            <MySelect
              options={getYears()}
              title={"Год производства автомобиля"}
              placeholder="Выберите год производства"
              value={year}
              onChange={setYear}
            />

            <MyInput
              title={"г/н автомобиля"}
              value={number}
              placeholder="Введите г/н автомобиля"
              onChange={setNumber}
              carNumber
            />
          </>
        )}

        <MyButton
          title="Создать"
          submit
          alt="Создать объект"
          fullWidth
          disabled={isDisabled}
          loading={loading}
          primary
        />
      </MyFlex>
    </Form>
  );
}
