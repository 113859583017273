import React, { useState, useEffect } from "react";
import { IFile } from "../../types/base_object";
import { generateImagePath } from "../../helpers";
import LayoutFormat from "../LayoutFormat";
import MyButton from "../MyButton";
import { MyModal } from "../MyModal";
/*
 * Объект стилей
 */
const styles = {
  img: {
    height: "100%",
    width: "100%",
    cursor: "pointer",
    border: "var(--border)",
    borderRadius: "var(--borderRadius)",
  },
  embed: {
    height: "100%",
    width: "100%",
    border: "var(--border)",
    borderRadius: "var(--borderRadius)",
  },
};
/*
 * Компонент для отображения PDF-файла
 */
export const PdfViewer = ({ file }: { file: IFile }) => {
  // Состояние видимости модального окна
  const [show, changeShow] = useState<boolean>(false);
  const [isPath, setPath] = useState<string>("");
  const [isAlt, setAlt] = useState<string>("");
  const [isThumb, setThumb] = useState<string>("");
  // Действия при инициализации компонента
  useEffect(() => {
    // Генерируем и добавляем путь к файлу
    setPath(generateImagePath(file?.name?.new));
    // Добавляем альтернативное имя файла
    setAlt(file?.name?.old);
    // Генерируем и добавляем путь к превью-изображению
    setThumb(generateImagePath(file?.thumb));
  }, [file]);
  // Возвращаем шаблон
  return (
    <>
      <LayoutFormat label="pdf">
        <img
          style={styles.img}
          src={isThumb}
          alt={isAlt}
          onClick={() => changeShow(true)}
        />
      </LayoutFormat>

      {show && (
        <MyModal onCancel={() => changeShow(false)}>
          <embed src={isPath} type="application/pdf" style={styles.embed} />

          <MyButton
            click={() => window.open(isPath, "_blank")}
            title="Открыть"
            alt="Открыть PDF"
            fullWidth
            primary
          />
        </MyModal>
      )}
    </>
  );
};
