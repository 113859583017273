import React, { useState } from "react";
import MyFlex from "../MyFlex";
import { DeleteFilled, DownloadOutlined } from "@ant-design/icons";
import { downloadImage } from "../../helpers";
import { delFile } from "../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../helpers/notify";
import { IFile } from "../../types/base_object";
import MyButton from "../MyButton";
import MyPopconfirm from "../MyPopconfirm";
/*
 * Интерфейс входных данных
 */
interface IProps {
  id: string | undefined;
  itemid: string | undefined;
  getData: Function;
  file: IFile;
}
/*
 * Компонент кнопок [скачать, удалить]
 */
export default function (props: IProps) {
  const { itemid, getData, file, id } = props;
  // Инициализируем состояние загрузки при удалении
  const [delLoading, setDelLoading] = useState<boolean>(false);
  // Инициализируем состояние загрузки при удалении
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  // Функция отправки запроса на удаление файла
  const isDelete = async () => {
    // Устанавливаем состояние загрузки удаления
    setDelLoading(true);
    // Основная логика
    try {
      // Отправляем запрос на удаление файла
      const { data, error } = await delFile(
        file?.name?.new,
        id || "",
        itemid || "",
      );
      // Если есть ошибка, показываем уведомление об ошибке
      if (error) return showErrorNotify(error);
      // Показываем уведомление об успешном удалении
      showSuccessNotify(data);
      // Функция получения данных
      await getData();
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Сбрасываем состояние загрузки удаления
      setDelLoading(false);
    }
  };
  const isDownloading = () => {
    // Устанавливаем состояние скачивания
    setDownloadLoading(true);
    // Основная логика
    try {
      // Метод скачивания файла с сервера
      downloadImage(file?.name?.new);
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Сбрасываем состояние скачивания
      setDownloadLoading(false);
    }
  };
  // Возвращаем шаблон компонента
  return (
    <MyFlex>
      <MyPopconfirm title="Удалить файл?" onConfirm={() => isDelete()}>
        <MyButton
          key={`${file?.name?.new}-del`}
          loading={delLoading}
          icon={<DeleteFilled />}
          fullWidth
          alt="Удалить файл"
          red
        />
      </MyPopconfirm>

      <MyButton
        key={`${file?.name?.new}`}
        click={() => isDownloading()}
        loading={downloadLoading}
        icon={<DownloadOutlined />}
        fullWidth
        alt="Скачать файл"
        primary
      />
    </MyFlex>
  );
}
