import React, { useState, useEffect, useMemo } from "react";
import { Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import Field from "../components/Field";
import { maskCost, sanitize } from "../../../helpers";
import { myFetch } from "../../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../../helpers/notify";
import { IForm } from "../../../types/props";
import { IOption } from "../../../types";
import MyInput from "../../MyInput";
import MyButton from "../../MyButton";
import MyFlex from "../../MyFlex";
/*
 * Компонент редактирования детали объекта
 */
export default function ({ getData, toClose, isData }: IForm) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [milleage, setMilleage] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  // Получаем ID объекта
  const { id } = useParams();
  // Очистка всех полей
  const allClear = () => {
    setName("");
    setDescription("");
    setMilleage("");
    setOptions({});
    setLoading(false);
  };
  // Действия при инициализации компонента
  useEffect(() => {
    // Очистка всех полей
    allClear();
    // Если у объекта есть опции
    if (isData?.options) {
      // Перебор имеющихся опций
      for (let key of Object.keys(isData?.options)) {
        // Добавление имеющихся опций
        setOptions((prevFields) => ({
          ...prevFields,
          [key]: {
            name: sanitize(isData?.options[key]?.name),
            cost: maskCost(isData?.options[key]?.cost),
          },
        }));
      }
    }
  }, []);
  // Действия при изменении isData
  useEffect(() => {
    setName(isData?.name);
    setDescription(isData?.description);
    setMilleage(maskCost(isData?.milleage));
  }, [isData]);
  // Добавление опции
  const addField = (fieldName: string) => {
    setOptions((prevFields) => ({
      ...prevFields,
      [fieldName]: "",
    }));
  };

  const changeField = (name: string, value: Object) => {
    setOptions((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const deleteField = (nameToDelete: string) => {
    setOptions((prev) => {
      const updatedOptions: { [key: string]: IOption } = { ...prev };
      delete updatedOptions[nameToDelete];

      return updatedOptions;
    });
  };

  const isSave = async () => {
    setLoading(true);

    try {
      const object = {
        name,
        description,
        milleage,
        options,
        objectId: id,
      };

      const { data, error } = await myFetch(
        `detail/${isData?.id}`,
        object,
        "PUT",
      );

      if (error) return showErrorNotify(error);

      showSuccessNotify(data);

      await getData();

      toClose(false);
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Очистка всех полей
      allClear();
    }
  };
  // Определение активности кнопки для формы
  const isDisabled = useMemo(() => {
    return !name;
  }, [name]);
  // Возвращаем шаблон
  return (
    <Form style={{ minWidth: "20rem" }} onFinish={isSave}>
      <MyFlex vertical>
        <MyInput
          title={"Название"}
          value={name}
          onChange={setName}
          placeholder="Введите название"
        />

        <MyInput
          title={"Описание"}
          value={description}
          onChange={setDescription}
          placeholder="Введите описание"
        />

        {!!isData?.milleage && (
          <MyInput
            title={"Пробег"}
            value={milleage}
            onChange={setMilleage}
            placeholder="Введите пробег"
            milleage
          />
        )}

        {Object.keys(options).map((fieldName) => (
          <Field
            key={fieldName}
            fieldName={fieldName}
            onFieldChange={changeField}
            onDeleteField={deleteField}
            //@ts-ignore
            value={options[fieldName]}
          />
        ))}

        <MyButton
          alt="Добавить затрату"
          click={() => addField(`${Date.now()}`)}
          icon={<PlusOutlined style={{ color: "var(--green)" }} />}
          fullWidth
          green
        />

        <MyButton
          alt="Сохранить изменения"
          title="Сохранить"
          submit
          fullWidth
          loading={loading}
          disabled={isDisabled}
          primary
        />
      </MyFlex>
    </Form>
  );
}
