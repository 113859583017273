import React, { memo, ReactElement } from "react";
import { VideoPlayer } from "../VideoPlayer";
import { PdfViewer } from "../PdfViewer";
import ImageViewer from "../ImageViewer";
import { generateImagePath } from "../../helpers";
import { IFile } from "../../types/base_object";
/*
 * Функция для определения, какой элемент рендерить
 */
const renderFile = (file: IFile, single: Boolean): ReactElement | null => {
  // Имя файла
  const fileName = file?.name?.new;
  // Генерируем путь до файла
  const filePath = generateImagePath(file?.thumb ? file?.thumb : fileName);
  // Проверяем условие
  if (fileName?.endsWith(".webp")) {
    // Возвращаем компонент
    return <ImageViewer src={filePath} alt={file?.name?.old} single={single} />;
    // Проверяем условие
  } else if (fileName?.endsWith(".pdf")) {
    // Возвращаем компонент
    return <PdfViewer file={file} />;
    // Проверяем условие
  } else if (fileName?.endsWith(".mp4")) {
    // Возвращаем компонент
    return <VideoPlayer file={file} />;
  }
  // Возвращаем null
  return null;
};
/*
 * Компонент кнопок [скачать, удалить]
 */
const RenderItem = ({
  file,
  single,
}: {
  file: IFile;
  single: Boolean;
}): ReactElement => {
  // Возвращаем шаблон компонента
  return (
    <div
      style={{
        borderRadius: "var(--borderRadius)",
        overflow: "hidden",
        height: "148px",
        width: "100%",
      }}
    >
      {renderFile(file, single)}
    </div>
  );
};
/*
 * Экспорт
 */
export default memo(RenderItem);
