import React, { memo } from "react";
import { useDispatch } from "react-redux";
//@ts-ignore
import { changeViewMode } from "../../store/reducers/actions.js";
import { BorderlessTableOutlined } from "@ant-design/icons";
import { UnorderedListOutlined } from "@ant-design/icons";
import { setLocalStorageData } from "../../helpers/localstorage";
import { useSelector } from "react-redux";
import { IState } from "../../types/store";
import MyButton from "../MyButton";
/*
 * Компонент Toggle
 */
const ToggleView = () => {
  // Режим отображения
  const mode = useSelector((state: IState) => state.view);
  // Действия в redux
  const dispatch = useDispatch();
  // Функция изменения режима отображения
  const handleChangeViewMode = (value: string) => {
    // Добавляем режим отображения в localstorage
    setLocalStorageData("mode", value);
    // Добавляем в store режим отображения
    dispatch(changeViewMode(value));
  };
  // Возвращаем шаблон
  return (
    <div
      style={{
        width: "max-content",
        display: "flex",
        gap: "var(--gap)",
        marginLeft: "auto",
      }}
    >
      <MyButton
        click={() => handleChangeViewMode("grid")}
        icon={<BorderlessTableOutlined />}
        alt="Отображение карточками"
        primary={mode === "grid"}
      />

      <MyButton
        click={() => handleChangeViewMode("list")}
        icon={<UnorderedListOutlined />}
        alt="Отображение списком"
        primary={mode === "list"}
      />
    </div>
  );
};
/*
 * Экспорт
 */
export default memo(ToggleView);
