import React, { useState, memo } from "react";
import LayoutFormat from "../LayoutFormat";
import { MyModal } from "../MyModal";
/*
 * Интерфейс входных данных
 */
interface IProps {
  src: string;
  alt: string;
  single?: Boolean;
}
/*
 * Объект стилей
 */
const styles = {
  img: {
    width: "100%",
    height: "100%",
    border: "var(--border)",
    borderRadius: "var(--borderRadius)",
  },
  bigImg: {
    maxHeight: "100%",
    maxWidth: "95%",
    border: "var(--border)",
    borderRadius: "var(--borderRadius)",
  },
};
/*
 * Компонент изображения файла
 */
const ImageViewer = ({ src, alt = "", single = false }: IProps) => {
  // Состояние видимости модального окна
  const [show, changeShow] = useState<boolean>(false);
  // Возвращаем шаблон
  return (
    <>
      <LayoutFormat label="webp" single={single}>
        <img
          style={{
            cursor: "pointer",
            ...styles.img,
          }}
          src={src}
          alt={alt}
          onClick={() => changeShow(single ? false : true)}
          loading="lazy"
        />
      </LayoutFormat>

      {show && (
        <MyModal onCancel={() => changeShow(false)}>
          <img style={styles.bigImg} src={src} alt={alt} />
        </MyModal>
      )}
    </>
  );
};
/*
 * Экспорт
 */
export default memo(ImageViewer);
