import React, { ReactElement, useState } from "react";
import Row from "./Row";
import MyInput from "../MyInput";
/*
 * Объект стилей
 */
const styles = {
  wrap: { display: "flex", gap: "var(--gap)" },
  filters: { display: "flex" },
  table: {
    display: "flex",
    gap: "var(--gap-extraSmall)",
    border: "var(--border)",
    backgroundColor: "var(--gray)",
    borderRadius: "var(--borderRadius)",
    overflow: "hidden",
  },
  input: {
    width: "50%",
    display: "flex",
    marginLeft: "auto",
  },
};
/*
 * Компонент таблицы
 */
export default function ({
  list,
  toInsidePage,
}: {
  list: any;
  toInsidePage: Function;
}): ReactElement {
  const [search, setSearch] = useState<string>("");
  // Возвращаем шаблон
  return (
    <div style={{ ...styles.wrap, flexDirection: "column" }}>
      <div style={styles.filters}>
        <div style={{ ...styles.input, paddingLeft: "var(--paddingSmall)" }}>
          <MyInput
            title={"Поиск"}
            value={search}
            onChange={setSearch}
            placeholder="Введите название"
          />
        </div>

        <div style={{ ...styles.input, paddingLeft: "var(--paddingSmall)" }}>
          <MyInput
            title={"Поиск"}
            value={search}
            onChange={setSearch}
            placeholder="Введите название"
          />
        </div>
      </div>

      <div style={{ ...styles.table, flexDirection: "column" }}>
        {list.map((item: any, i: number) => {
          return <Row data={item} key={item.id} toInsidePage={toInsidePage} />;
        })}
      </div>
    </div>
  );
}
