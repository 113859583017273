import React, { ReactElement, memo } from "react";
/*
 * Интерфейс входных данных
 */
interface IProps {
  children: any;
  between?: boolean;
  vertical?: boolean;
  center?: boolean;
  end?: boolean;
  full?: boolean;
  height?: string;
  width?: string;
}
/*
 * Кастомный компонент Flex
 */
const Flex = ({
  children,
  between,
  vertical,
  center,
  end,
  full,
  height,
  width,
}: IProps): ReactElement => {
  // Возвращаем шаблон
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        gap: "var(--gap)",
        justifyContent: between
          ? "space-between"
          : center
            ? "center"
            : end
              ? "end"
              : "",
        flexDirection: vertical ? "column" : "row",
        alignItems: center ? "center" : "",
        width: full ? "100vw" : width ? width : "100%",
        height: full ? "100vh" : height ? height : "max-content",
      }}
    >
      {children}
    </div>
  );
};
/*
 * Экспорт
 */
export default memo(Flex);
