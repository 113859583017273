import React, { ReactElement } from "react";
import MyFlex from "../MyFlex";
import { getFormatDate, maskCost } from "../../helpers";
import MyDivide from "../MyDivide";
import MyEllipsisRow from "../MyEllipsisRow";
/*
 * Объект стилей
 */
const styles = {
  row: {
    fontSize: "var(--fontSizeSmall)",
    color: "var(--black)",
    cursor: "default",
  },
};
/*
 * Компонент подробного описания
 */
export default function ({ data }: { data: any }): ReactElement {
  // Возвращаем шаблон компонента
  return (
    <MyFlex vertical>
      <MyEllipsisRow
        text="Дата создания"
        span={getFormatDate(data.date.start)}
      />

      {!!data?.name && <MyEllipsisRow text="Название" span={data?.name} />}

      {!!data?.description && (
        <MyEllipsisRow text="Описание" span={data?.description} />
      )}

      {!!data?.sum && (
        <MyEllipsisRow text="Сумма затрат" span={maskCost(data?.sum)} />
      )}

      {!!data?.brand && <MyEllipsisRow text="Бренд" span={data?.brand} />}

      {!!data?.model && <MyEllipsisRow text="Модель" span={data?.model} />}

      {!!data?.year && <MyEllipsisRow text="Год выпуска" span={data?.year} />}

      {!!data?.number && (
        <MyEllipsisRow text="Гос. номер" span={data?.number} />
      )}

      {!!data?.milleage && (
        <MyEllipsisRow text="Пробег" span={`${maskCost(data?.milleage)} км`} />
      )}

      {!!data?.options && <MyDivide title="детали" />}

      {!!data?.options &&
        Object.keys(data?.options).map((key, i) => {
          return (
            <MyEllipsisRow
              text={data?.options[key]?.name}
              span={maskCost(data?.options[key]?.cost)}
              key={i}
            />
          );
        })}
    </MyFlex>
  );
}
