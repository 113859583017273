import { notification } from "antd";
/*
 * Конфигурация глобальных параметров для всех уведомлений
 */
notification.config({
  top: 48, // Расстояние от верхней части окна до уведомления
  closeIcon: false, // Убирает кнопку закрытия (крестик) из всех уведомлений
});
/*
 * Задаем стиль для уведомлений
 */
const style = {
  height: "max-content", // Устанавливаем высоту по содержимому
  width: "max-content", // Устанавливаем ширину по содержимому
};
/*
 * Функция для отображения уведомления об ошибке
 */
export const showErrorNotify = (description: string = ""): void => {
  // Показываем уведомление с ошибкой, используя заданный стиль
  notification.error({ message: "", description, style });
};
/*
 * Функция для отображения уведомления об успехе
 */
export const showSuccessNotify = (description: string = ""): void => {
  // Показываем уведомление об успехе, используя заданный стиль
  notification.success({ message: "", description, style });
};
