export const createEl = (tag: string) => document.createElement(tag);

export const createWrapBtns = () => {
  const el = createEl("div");
  el.style.display = "flex";
  el.style.gap = ".5rem";

  return el;
};

export const createText = () => {
  const el = createEl("p");
  el.textContent = "хотите установить приложение ?";

  return el;
};

export const createButton = (is: boolean) => {
  const el = createEl("button");
  el.style.padding = "0.25rem .5rem";
  el.style.border = "none";
  el.style.cursor = "pointer";
  el.style.borderRadius = ".25rem";
  el.style.backgroundColor = "transparent";
  el.textContent = is ? "да" : "нет";

  return el;
};

export const createProposeToInstall = () => {
  const padding = ".5rem";
  const position = "0";
  const el = createEl("div");
  el.id = "proposeToInstall";
  el.style.width = "100vw";
  el.style.position = "fixed";
  el.style.display = "flex";
  el.style.flexDirection = "column";
  el.style.justifyContent = "center";
  el.style.alignItems = "center";
  el.style.paddingTop = padding;
  el.style.paddingBottom = padding;
  el.style.color = "black";
  el.style.backgroundColor = "#91bfff";
  el.style.bottom = position;
  el.style.left = position;

  return el;
};

export const createNoContentBlock = () => {
  const el = createEl("div");
  el.id = "noConnectionBlock";
  el.textContent = "нет интернет соединения";
  el.style.color = "white";
  el.style.backgroundColor = "red";
  el.style.padding = ".25rem";
  el.style.margin = "0";
  el.style.textAlign = "center";

  return el;
};

export const getEl = (id: string) => document.getElementById(id);
