import React, { ReactElement, useState } from "react";
import MyButton from "../MyButton";
import MyFlex from "../MyFlex";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { myFetch } from "./../../helpers/my_fetch";
/*
 * Интерфейс входных данных
 */
interface IProps {
  getData: Function;
  parentId: String | undefined;
  objectId: String;
  pageName: String;
  currentIndex: number;
  length: number;
}
/*
 * Компонент кнопок перемещения объектов по вертикале
 */
export default function ({
  getData,
  objectId,
  parentId,
  currentIndex,
  length,
}: IProps): ReactElement {
  // Статус Loader для кнопки поднятия
  const [upBtnLoader, setUpBtnLoader] = useState<boolean>(false);
  // Статус Loader для кнопки опускания
  const [downBtnLoader, setDownBtnLoader] = useState<boolean>(false);
  // Функция изменения очередности файлов
  const changeIndex = async (direction: number) => {
    // Меняем статус Loader кнопки
    if (direction) setUpBtnLoader(true);
    // Меняем статус Loader кнопки
    else setDownBtnLoader(true);
    // Основная логика
    try {
      // Отправляем данные на сервер
      await myFetch("swap", { direction, objectId, parentId }, "POST");
      // Запрос данных
      await getData();
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Меняем статус Loader кнопки
      setUpBtnLoader(false);
      // Меняем статус Loader кнопки
      setDownBtnLoader(false);
    }
  };
  // Возвращаем шаблон
  return (
    <MyFlex width="max-content">
      <MyButton
        alt="Переместить вверх"
        click={() => changeIndex(1)}
        disabled={currentIndex === 0}
        primary={currentIndex !== 0}
        icon={<UpOutlined />}
        loading={upBtnLoader}
      />

      <MyButton
        alt="Переместить вниз"
        click={() => changeIndex(0)}
        disabled={currentIndex === length - 1}
        primary={currentIndex !== length - 1}
        icon={<DownOutlined />}
        loading={downBtnLoader}
      />
    </MyFlex>
  );
}
