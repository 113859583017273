import React, { useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Collapse } from "antd";
import { DeleteFilled, EditFilled, CloseOutlined } from "@ant-design/icons";
import { generateImagePath } from "../../helpers";
import { myFetch } from "../../helpers/my_fetch";
import { UploadImages } from "../UploadImages";
import { IHeaderInside } from "../../types/props";
import { IBreakpoint, IState } from "../../types/store";
import InsidePageBtns from "../Controls/Buttons";
import ImageViewer from "../ImageViewer";
import MyButton from "../MyButton";
import MyDivide from "../MyDivide";
import MyDescription from "../MyDescription";
import MyFlex from "../MyFlex";
import MyPopconfirm from "../MyPopconfirm";
import plugCar from "../../assets/plug_car.png";
import plugObject from "../../assets/plug_object.png";
/*
 * Header внутренней страницы
 */
export default function ({ isData, getData, id }: IHeaderInside) {
  // Флаг о том (является ли сейчас ширина экрана, как у Desktop устройств)
  const { desktop }: IBreakpoint = useSelector(
    (state: IState) => state.breakpoints,
  );
  // Текущая ширина экрана устройства
  const { width } = useSelector((state: IState) => state.windowSize);
  // Флаг о том (является ли сейчас ширина экрана, как у Desktop устройств)
  const isDesktop = useMemo(() => width >= desktop, [width, desktop]);
  // Флаг видимости компонента Loader при удалении изображения
  const [loaderDelImage, setLoaderDelImage] = useState(false);
  // Флаг о редактировании изображения
  const [changeImage, setChangeImage] = useState(false);
  // Функция удаления изображения объекта/машины
  const delImage = useCallback(async () => {
    // Изменение флага видимости компонента Loader
    setLoaderDelImage(true);
    // Основная логика
    try {
      // Отправка запроса для удаления изображения на сервер
      await myFetch(
        `${id}/file/main`,
        { name: isData?.image?.name?.new },
        "DELETE",
      );
      // Ссылка на функцию запроса данных
      await getData();
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Изменение флага видимости компонента Loader
      setLoaderDelImage(false);
    }
  }, [isData]);
  // Получение пути к изображению для карточки
  const getImagePath = useCallback(() => {
    const img = isData?.image?.thumb
      ? isData?.image?.thumb
      : isData?.image?.name?.new;
    // Если есть название картинки
    if (img) {
      // Генерация пути к картинке на сервере
      return generateImagePath(img);
    } else {
      // Тип объекта
      const type = isData?.type;
      // Если карточка для объекта
      if (type === "object") {
        // Возвращаем заглушку для объекта
        return plugObject;
      }
      // Если карточка для объекта
      if (type === "car") {
        // Возвращаем заглушку для машины
        return plugCar;
      }
      // Возвращаем пустую строку
      return "";
    }
  }, []);
  // Возвращаем шаблон компонента
  return (
    <MyFlex vertical>
      <MyFlex vertical={!isDesktop}>
        {changeImage ? (
          <MyFlex vertical width={isDesktop ? "max-content" : "100%"}>
            <UploadImages
              single={true}
              getData={getData}
              toClose={async () => {
                // Меняем статус изменения изображения
                setChangeImage(false);
                // Запрос данных
                await getData();
              }}
            />

            <MyButton
              click={() => setChangeImage(false)}
              icon={<CloseOutlined />}
              alt="Закрыть редактирование изображения"
              fullWidth
              red
            />
          </MyFlex>
        ) : (
          <MyFlex vertical width={isDesktop ? "max-content" : "100%"}>
            <div
              style={{
                height: isDesktop
                  ? "var(--uploadFileSingleDesktopHeight)"
                  : "var(--uploadFileSingleMobileHeight)",
                width: isDesktop
                  ? "var(--uploadFileSingleDesktopWidth)"
                  : "var(--uploadFileSingleMobileWidth)",
              }}
            >
              <ImageViewer
                src={getImagePath()}
                alt={isData?.image?.name?.old}
                single={true}
              />
            </div>

            <MyFlex>
              <MyButton
                click={() => setChangeImage(true)}
                alt="Заменить изображенипе"
                icon={<EditFilled />}
                primary
                fullWidth
              />

              {isData?.image && (
                <MyPopconfirm
                  title="Удалить изображение?"
                  onConfirm={() => delImage()}
                  width="100%"
                >
                  <MyButton
                    alt="Удалить изображение"
                    icon={<DeleteFilled />}
                    red
                    loading={loaderDelImage}
                    fullWidth
                  />
                </MyPopconfirm>
              )}
            </MyFlex>
          </MyFlex>
        )}

        <MyDescription data={isData} />

        <InsidePageBtns getData={getData} isData={isData} id={String(id)} />

        {!isDesktop && <MyDivide title="информация" />}
      </MyFlex>

      <Collapse
        items={[
          {
            key: 1,
            label: "файлы",
            children: (
              <UploadImages
                getData={getData}
                files={isData?.files}
                single={false}
              />
            ),
          },
        ]}
      />
    </MyFlex>
  );
}
