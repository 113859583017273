import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  ReactElement,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { myFetch } from "../helpers/my_fetch";
import { Modal, Col, Row } from "antd";
import MyFlex from "../components/MyFlex";
import CreateCar from "../components/Forms/Create";
import TheCard from "../components/MyCard";
import MySpiner from "../components/MySpiner";
//@ts-ignore
import { PlusCircleFilled } from "@ant-design/icons";
import { showErrorNotify } from "../helpers/notify";
import ModeControls from "../components/Controls";
import { IState } from "../types/store";
import { IRes } from "../types/index";
import MyTable from "../components/MyTable";
import MyButton from "../components/MyButton";
/*
 * Страница списка объектов
 */
export default function (): ReactElement {
  // Режим отображения
  const mode = useSelector((state: IState) => state.view);
  // Список
  const [list, setList] = useState([]);
  // Статус загрузки
  const [loader, setLoader] = useState(false);
  // Статус показа модалки
  const [isModal, setIsModal] = useState(false);
  // Ключ для модалки
  const [modalKey, setModalKey] = useState(Date.now());
  // Запрос данных с сервера
  const getData = useCallback(async () => {
    // Меняем статус показа Loader
    setLoader(true);
    // Основная логика
    try {
      // Делаем запрос к серверу
      const { data, error }: IRes = await myFetch("", {}, "GET", 1);
      // Если ошибка
      if (error) return showErrorNotify(error);
      // Добавляем полученные данные
      setList(data);
    } catch ({ message }: any) {
      // console.log(message);
    } finally {
      // Меняем статус загрузки
      setLoader(false);
    }
  }, []);
  // Определяем вид списка карточек
  const isGrid = useMemo(() => mode === "grid", [mode]);
  // Действия при изменении getData
  useEffect(() => {
    // Запрос данных с сервера
    getData();
  }, [getData]);
  // Функция закрытия модального окна
  const toClose = useCallback(async () => {
    // Меняем статус показа модалки
    setIsModal(false);
    // Добавляем/обновляем ключ для модалки
    setModalKey(Date.now());
  }, []);
  // Объект навигации
  const navigate = useNavigate();
  // Функция перехода на внутреннею страницу объекта
  const toInsidePage = (obj: any) => {
    // Перенаправление
    navigate(`/inside/${obj?.id}`);
  };
  // Показываем loader
  if (loader) {
    return (
      <MyFlex center height="100%">
        <MySpiner big />
      </MyFlex>
    );
  }
  // Возвращаем шаблон компонента
  return (
    <MyFlex vertical>
      <MyFlex between>
        <MyButton
          click={() => setIsModal(true)}
          icon={<PlusCircleFilled />}
          alt="Создать объект"
          green
        />

        <ModeControls />
      </MyFlex>

      {!isGrid && <MyTable list={list} toInsidePage={toInsidePage} />}

      {isGrid && (
        <Row gutter={[8, 8]}>
          {list &&
            list.map((object: any) => {
              return (
                <Col xs={12} sm={8} lg={6} xl={4} key={object?.id}>
                  <TheCard object={object} />
                </Col>
              );
            })}
        </Row>
      )}

      <Modal open={isModal} onCancel={toClose}>
        <CreateCar getData={getData} toClose={toClose} key={modalKey} />
      </Modal>
    </MyFlex>
  );
}
