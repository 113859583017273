import React, { ReactElement, useState } from "react";
/*
 * Объект стилей
 */
const styles = {
  wrap: {
    width: "100%",
    display: "flex",
    padding: "var(--paddingSmall)",
    cursor: "pointer",
  },
  text: {},
};
/*
 * Компонент строки таблицы
 */
export default function ({
  data,
  toInsidePage,
}: {
  data: any;
  toInsidePage: Function;
}): ReactElement {
  // Стейт для отслеживания наведения
  const [isHovered, setIsHovered] = useState(false);
  // Возвращаем шаблон
  return (
    <div
      style={
        isHovered
          ? {
              backgroundColor: "var(--gray)",
              ...styles.wrap,
            }
          : { backgroundColor: "var(--white)", ...styles.wrap }
      }
      onClick={() => toInsidePage(data)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <p style={styles.text}>{data?.name || data?.brand || ""}</p>
    </div>
  );
}
