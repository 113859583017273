import React, { useState, useMemo, ReactElement } from "react";
import { Checkbox, Form, notification } from "antd";
import MyFlex from "../components/MyFlex";
import Logo from "../components/MyLogo";
import { myFetch } from "../helpers/my_fetch";
import {
  setLocalStorageData,
  getStatusLocalStorageData,
} from "../helpers/localstorage";
import { setCookie, getStatusCookie } from "../helpers/cookie";
import { Navigate, useNavigate } from "react-router-dom";
import { IRes } from "../types/index";
import MyInput from "../components/MyInput";
import MyButton from "../components/MyButton";

export default function (): ReactElement {
  // Объект навигации
  const navigate = useNavigate();

  const [phone, setPhone] = useState("89024050221");
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [isAuthForm, setIsAuthForm] = useState(true);
  const [password, setPassword] = useState("12345678");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [remember, setRemember] = useState(false);

  const statusLocalstorage = getStatusLocalStorageData();
  const statusCookie = getStatusCookie();

  const minLengthPass = 8;
  const lengthPhone = 17;

  const isDisabled = useMemo(() => {
    if (isAuthForm)
      return (
        !Boolean(password.length >= minLengthPass) ||
        !Boolean(phone.length === lengthPhone)
      );
    else
      return (
        !Boolean(repeatPassword === password) ||
        !Boolean(password.length >= minLengthPass) ||
        !Boolean(phone.length)
      );
  }, [isAuthForm, phone, password, repeatPassword]);

  const changeRemember = ({ target }: any): void => {
    setRemember(target.checked);
  };

  const clearInputs = (): void => {
    setPhone("");
    setPassword("");
    setRepeatPassword("");
  };

  const changeIsForm = (): void => {
    clearInputs();
    setIsAuthForm(!isAuthForm);
  };

  const showErrNotify = (err: string) => {
    notification.error({
      message: "ошибка ",
      description: err,
    });
  };

  const auth = async () => {
    if (isDisabled) return;

    setLoadingAuth(true);

    try {
      const body = { phone: phone.replace(/[ )(-]/g, ""), password };

      const { data, error }: IRes = await myFetch("user/auth", body, "POST");

      if (error) return showErrNotify(error);

      const accessKeys = ["_id", "name"];

      if (remember) {
        for (const key of accessKeys) {
          setLocalStorageData(key === "_id" ? "id" : key, data[key]);
        }
      } else {
        for (const key of accessKeys) {
          setCookie(key === "_id" ? "id" : key, data[key]);
        }
      }
      // Перенаправление
      navigate("/");
    } catch (e) {
      showErrNotify("ошибка авторизации");
    } finally {
      setLoadingAuth(false);
      clearInputs();
    }
  };

  const register = async () => {
    if (isDisabled) return;

    setLoadingRegister(true);

    try {
      const body = { phone: phone.replace(/[ )(-]/g, ""), password };

      const { error } = await myFetch("user/register", body, "POST");

      if (error) return showErrNotify(error);

      notification.success({
        message: "успешная регистрация",
      });

      setIsAuthForm(true);
    } catch (e) {
      showErrNotify("ошибка регистрации");
    } finally {
      setLoadingRegister(false);
      clearInputs();
    }
  };

  return statusLocalstorage || statusCookie ? (
    <Navigate to="/" />
  ) : (
    <MyFlex center full>
      <Form
        style={{ minWidth: "20rem" }}
        onFinish={isAuthForm ? auth : register}
      >
        <MyFlex vertical>
          <MyFlex center>
            <Logo />
          </MyFlex>

          <MyInput
            title="Номер телефона"
            value={phone}
            onChange={setPhone}
            placeholder="Введите номер телефона"
            phoneNumber
          />

          <MyInput
            title={isAuthForm ? "Пароль" : "Пароль (минимум 8 символов)"}
            value={password}
            onChange={setPassword}
            placeholder="Введите пароль"
          />

          {!isAuthForm && (
            <MyInput
              title="Повторите пароль"
              value={repeatPassword}
              onChange={setRepeatPassword}
              placeholder="Введите пароль"
            />
          )}

          <MyFlex vertical>
            {isAuthForm && (
              <Checkbox checked={remember} onChange={changeRemember}>
                оставаться в системе
              </Checkbox>
            )}

            <MyFlex between>
              <MyButton
                title={isAuthForm ? "Авторизоваться" : "Зарегистрироваться"}
                alt={isAuthForm ? "Авторизоваться" : "Зарегистрироваться"}
                submit
                loading={isAuthForm ? loadingAuth : loadingRegister}
                disabled={isDisabled}
                green
              />

              <MyButton
                title={isAuthForm ? "Регистрация" : "Авторизация"}
                alt={isAuthForm ? "Регистрация" : "Авторизация"}
                click={() => changeIsForm()}
                primary
              />
            </MyFlex>
          </MyFlex>
        </MyFlex>
      </Form>
    </MyFlex>
  );
}
