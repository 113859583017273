import React, { ReactElement, memo } from "react";
import MySpiner from "../MySpiner";
import MyFlex from "../MyFlex";
/*
 * Интерфейс входных данных
 */
interface IProps {
  title?: string;
  alt?: string;
  icon?: any;
  fullWidth?: boolean;
  click?: Function;
  submit?: boolean;
  disabled?: boolean;
  loading?: boolean;
  primary?: boolean;
  red?: boolean;
  green?: boolean;
}
/*
 * Объект стилей
 */
const styles = {
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "var(--fontSizeBase)",
    backgroundColor: "var(--white)",
    border: "var(--border)",
    borderRadius: "var(--borderRadius)",
    padding: "var(--paddingSmall) var(--paddingBase)",
    cursor: "pointer",
    minHeight: "2.0313rem",
    maxHeight: "2.0313rem",
  },
};
/*
 * Компонент кнопки
 */
const MyButton = ({
  title = "",
  alt = "",
  fullWidth = false,
  submit = false,
  disabled = false,
  loading = false,
  primary = false,
  red = false,
  green = false,
  icon,
  click,
}: IProps): ReactElement => {
  // Возвращаем шаблон
  return (
    <button
      style={{
        ...styles.btn,
        appearance: "none",
        width: fullWidth ? "100%" : "max-content",
        cursor: disabled ? "not-allowed" : "pointer",
        color: disabled
          ? "var(--gray)"
          : primary
            ? "var(--primary)"
            : red
              ? "var(--red)"
              : green
                ? "var(--green)"
                : "var(--gray)",
        borderColor: disabled
          ? "var(--gray)"
          : primary
            ? "var(--primary)"
            : red
              ? "var(--red)"
              : green
                ? "var(--green)"
                : "var(--gray)",
      }}
      onClick={() => (click ? click() : "")}
      title={alt}
      type={submit ? "submit" : "button"}
      disabled={disabled}
    >
      {loading ? (
        <MyFlex center height="100%">
          <MySpiner />
        </MyFlex>
      ) : icon ? (
        icon
      ) : (
        title
      )}
    </button>
  );
};
/*
 * Экспорт
 */
export default memo(MyButton);
