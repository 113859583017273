import React, { useState, memo, useCallback, ReactElement } from "react";
import { Collapse, Modal } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { myFetch } from "../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../helpers/notify";
import { IDetail } from "../../types/base_object";
import { UploadImages } from "../../components/UploadImages";
import EditDetail from "../Forms/Edit/Detail";
import MoveControls from "../../components/MoveControls";
import DownloadBtn from "../../components/Controls/DownloadBtn";
import MyDescription from "../../components/MyDescription";
import MyButton from "../MyButton";
import MyFlex from "../MyFlex";
import MyPopconfirm from "../MyPopconfirm";
/*
 * Интерфейс входных данных
 */
export interface IRow {
  getData: Function;
  isData: IDetail;
  id?: String;
  isPage: String;
  index: number;
  length: number;
}
/*
 * Компонент строки
 */
const Row = ({
  getData,
  isData,
  id,
  isPage,
  index,
  length,
}: IRow): ReactElement => {
  // Состояние видимости компонента Loader
  const [loader, setLoader] = useState(false);
  // Состояние видимости модального окна
  const [isModal, setIsModal] = useState(false);
  // Состояние key модального окна
  const [modalKey, setModalKey] = useState(Date.now());
  // Функция удаления
  const del = useCallback(async () => {
    // Меняем флаг видимости модального окна
    setLoader(true);
    // Основная логика
    try {
      // Метод удаления объекта
      const { data, error } = await myFetch(`${id}/${isData.id}`, {}, "DELETE");
      // Показываем notify ошибки
      if (error) return showErrorNotify(error);
      // Показываем notify
      showSuccessNotify(data);
      // Ссылка на функцию запроса данных
      await getData();
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Меняем флаг видимости модального окна
      setLoader(false);
    }
  }, [isData]);
  // Функция закрытия модального окна
  const toClose = () => {
    // Меняем флаг видимости модального окна
    setIsModal(false);
    // Обновляем key модального окна (для очистки данных в модальном окне)
    setModalKey(Date.now());
  };
  // Возвращаем шаблон компонента
  return (
    <Collapse
      items={[
        {
          key: 1,
          label: isData?.name,
          children: (
            <MyFlex vertical>
              <MyFlex between>
                <MyFlex>
                  <MyButton
                    click={() => setIsModal(true)}
                    icon={<EditFilled />}
                    alt="Редактировать деталь объекта"
                    primary
                  />

                  <DownloadBtn
                    title="Скачать данные объекта"
                    objectName={isData.name}
                    objectId={isData.id}
                  />

                  <MyPopconfirm title="Удалить деталь?" onConfirm={() => del()}>
                    <MyButton
                      icon={<DeleteFilled />}
                      loading={loader}
                      alt="Удалить деталь"
                      red
                    />
                  </MyPopconfirm>
                </MyFlex>

                <MoveControls
                  getData={getData}
                  parentId={id}
                  objectId={isData?.id}
                  pageName={isPage}
                  currentIndex={index}
                  length={length}
                />
              </MyFlex>

              <Collapse
                items={[
                  {
                    key: 1,
                    label: "данные",
                    children: <MyDescription data={isData} />,
                  },
                ]}
              />

              <Collapse
                items={[
                  {
                    key: 1,
                    label: "файлы",
                    children: (
                      <UploadImages
                        files={isData?.files}
                        itemid={isData?.id ? String(isData?.id) : ""}
                        getData={getData}
                        single={false}
                      />
                    ),
                  },
                ]}
              />

              <Modal open={isModal} onCancel={toClose}>
                <EditDetail
                  getData={getData}
                  toClose={toClose}
                  isData={isData}
                  key={modalKey}
                />
              </Modal>
            </MyFlex>
          ),
        },
      ]}
    />
  );
};
/*
 * Экспорт
 */
export default memo(Row);
