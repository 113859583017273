import React, { useState, useEffect } from "react";
import MyButton from "../../MyButton";
import { MinusOutlined } from "@ant-design/icons";
import { IOption } from "../../../types";
import MyInput from "../../MyInput";
/*
 * Интерфейс входных данных
 */
interface IProps {
  fieldName: string;
  onFieldChange: Function;
  onDeleteField: Function;
  value: IOption;
}
/*
 * Объект стилей
 */
const styles = {
  wrap: {
    width: "100%",
    display: "flex",
    alignItems: "end",
    gap: "var(--gap)",
  },
};
/*
 * Компонент опции для детали объекта
 */
export default function ({
  fieldName,
  onFieldChange,
  onDeleteField,
  value,
}: IProps) {
  // State названия
  const [name, setName] = useState("");
  // State стоимость
  const [cost, setCost] = useState("");
  // Действия при изменении value
  useEffect(() => {
    if (value.name) setName(value.name);
    if (value.cost) setCost(value.cost);
  }, [value]);
  // Действия при изменении name и cost
  useEffect(() => {
    onFieldChange(fieldName, { name, cost });
  }, [name, cost]);
  // Возвращаем шаблон
  return (
    <div style={{ ...styles.wrap }}>
      <MyInput
        title={"Название"}
        value={name}
        onChange={setName}
        placeholder="Введите название"
      />

      <MyInput
        title={"Стоимость"}
        value={cost}
        onChange={setCost}
        placeholder="Введите стоимость"
        cost
      />

      <MyButton
        alt="Удалить опцию"
        icon={<MinusOutlined />}
        click={() => onDeleteField(fieldName)}
        red
      />
    </div>
  );
}
