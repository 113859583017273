import React, { ReactElement, memo } from "react";
import MyFlex from "../MyFlex";
import "./index.css";
/*
 * Интерфейс входных данных
 */
interface IProps {
  big?: boolean;
  children?: string;
}
/*
 * Компонент Spiner
 */
const MySpiner = ({
  big = false,
  children = "Загрузка...",
}: IProps): ReactElement => {
  return (
    <MyFlex center height="100%">
      <div
        style={{
          display: "flex",
          borderRadius: "50%",
          animation: "spin 1s linear infinite",
          alignItems: "center",
          justifyContent: "center",
          height: big ? "var(--iconSizeBig)" : "var(--iconSize)",
          width: big ? "var(--iconSizeBig)" : "var(--iconSize)",
          border: big ? "var(--borderBig)" : "var(--border)",
          borderTop: `var(${big ? "--borderSizeBig" : "--borderSize"}) solid var(--primary)`,
        }}
      />

      {big && (
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            translate: "-50% -50%",
          }}
        >
          {children}
        </span>
      )}
    </MyFlex>
  );
};
/*
 * Экспорт
 */
export default memo(MySpiner);
