import React, { useState, ReactElement, memo, useEffect, useRef } from "react";
import MyButton from "../MyButton";
import MyFlex from "../MyFlex";
import MyEllipsisRow from "../MyEllipsisRow";
/*
 * Интерфейс входных данных
 */
interface IProps {
  title: string;
  onConfirm: () => void;
  okText?: string;
  cancelText?: string;
  children: ReactElement;
  right?: boolean;
  left?: boolean;
  width?: string;
}
/*
 * Компонент Popconfirm
 */
const MyPopconfirm = ({
  title,
  onConfirm,
  okText = "Да",
  cancelText = "Нет",
  children,
  left = false,
  right = false,
  width = "max-content",
}: IProps): ReactElement => {
  // Состояни видимости Popconfirm
  const [visible, setVisible] = useState<boolean>(false);
  // Ссылка на компонент
  const isRef = useRef<HTMLDivElement>(null);
  // Функция закрытия компонента
  const hidePopconfirm = () => {
    setVisible(false);
  };
  // Закрытие при клике вне компонента
  useEffect(() => {
    const handleClickOutside = (ev: MouseEvent) => {
      if (isRef.current && !isRef.current.contains(ev.target as Node)) {
        // Закрываем Popconfirm
        hidePopconfirm();
      }
    };
    // Вешаем обработчик при наведении
    document.addEventListener("mousedown", handleClickOutside);
    // Действия при размонтировании компонента
    return () => {
      // Удаляем обработчик при размонтировании
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isRef]);
  // Возвращаем шаблон
  return (
    <div style={{ position: "relative", width: "100%" }} ref={isRef}>
      <div onClick={() => setVisible(true)}>{children}</div>

      {visible && (
        <div
          style={{
            width: "164px",
            position: "absolute",
            top: "110%",
            left: left ? "0" : right ? "100%" : "50%",
            transform: left
              ? "translateX(-50%)"
              : right
                ? "translateX(-100%)"
                : "translateX(-50%)",
            backgroundColor: "var(--white)",
            border: "var(--border)",
            borderRadius: "var(--borderRadius)",
            padding: "calc(var(--paddingBase) * 2)",
            zIndex: 10,
            boxShadow: "var(--boxShadow)",
            boxSizing: "content-box",
          }}
        >
          <MyFlex center vertical>
            <MyEllipsisRow text={title} center />

            <MyFlex center>
              <MyButton
                click={() => {
                  onConfirm();
                  hidePopconfirm();
                }}
                green
                alt="Подтверждение"
                title={okText}
              />

              <MyButton
                click={() => hidePopconfirm()}
                red
                alt="Отмена"
                title={cancelText}
              />
            </MyFlex>
          </MyFlex>
        </div>
      )}
    </div>
  );
};
/*
 * Экспорт
 */
export default memo(MyPopconfirm);
