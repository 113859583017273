export const brands = [
  { name: "газ" },
  { name: "камаз" },
  { name: "лада" },
  { name: "москвич" },
  { name: "уаз" },
  { name: "зил" },
  { name: "acura" },
  { name: "alfa romeo" },
  { name: "amc" },
  { name: "ashok leyland" },
  { name: "asia auto" },
  { name: "aston martin" },
  { name: "audi" },
  { name: "bentley" },
  { name: "belaz" },
  { name: "bmw" },
  { name: "bogdan" },
  { name: "buick" },
  { name: "byd" },
  { name: "cadillac" },
  { name: "chery" },
  { name: "chevrolet" },
  { name: "chrysler" },
  { name: "citroën" },
  { name: "dacia" },
  { name: "daewoo" },
  { name: "datsun" },
  { name: "delorean" },
  { name: "dodge" },
  { name: "dongfeng" },
  { name: "dordoi" },
  { name: "eagle" },
  { name: "eraz" },
  { name: "faw" },
  { name: "ferrari" },
  { name: "fiat" },
  { name: "fisker" },
  { name: "ford" },
  { name: "freightliner" },
  { name: "geely" },
  { name: "genesis" },
  { name: "geo" },
  { name: "gmc" },
  { name: "great wall" },
  { name: "haval" },
  { name: "honda" },
  { name: "hummer" },
  { name: "hyundai" },
  { name: "infiniti" },
  { name: "isuzu" },
  { name: "jaguar" },
  { name: "jeep" },
  { name: "karma" },
  { name: "kia" },
  { name: "kraz" },
  { name: "lamborghini" },
  { name: "land rover" },
  { name: "lexus" },
  { name: "lifan" },
  { name: "lincoln" },
  { name: "lotus" },
  { name: "lucid" },
  { name: "luxgen" },
  { name: "maz" },
  { name: "mahindra" },
  { name: "maruti suzuki" },
  { name: "maserati" },
  { name: "maybach" },
  { name: "mazda" },
  { name: "mclaren" },
  { name: "mercedes-benz" },
  { name: "mercury" },
  { name: "mini" },
  { name: "mitsubishi" },
  { name: "nissan" },
  { name: "naz" },
  { name: "nio" },
  { name: "oldsmobile" },
  { name: "opel" },
  { name: "peugeot" },
  { name: "plymouth" },
  { name: "pontiac" },
  { name: "porsche" },
  { name: "proton" },
  { name: "ram" },
  { name: "ravon" },
  { name: "renault" },
  { name: "rivian" },
  { name: "rolls-royce" },
  { name: "rover" },
  { name: "saab" },
  { name: "samauto" },
  { name: "saturn" },
  { name: "scion" },
  { name: "seat" },
  { name: "skoda" },
  { name: "smart" },
  { name: "ssangyong" },
  { name: "subaru" },
  { name: "suzuki" },
  { name: "tajikauto" },
  { name: "tata" },
  { name: "tesla" },
  { name: "toyota" },
  { name: "uz-daewoo" },
  { name: "vauxhall" },
  { name: "volkswagen" },
  { name: "volvo" },
  { name: "xpeng" },
  { name: "yugo" },
  { name: "zaz" },
];
