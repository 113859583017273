import { desiredKeys } from "./index";

export const getLocalStorageData = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const setLocalStorageData = (key: string, val: string): void => {
  localStorage.setItem(key, val);
};

export const delLocalStorageData = (key: string): void => {
  localStorage.removeItem(key);
};

export const getStatusLocalStorageData = (): boolean => {
  let status = false;

  for (const key of desiredKeys) {
    status = Boolean(getLocalStorageData(key));
  }

  return status;
};

export const clearLocalstorage = (): void => {
  localStorage.clear();
};
