import React, { ReactElement } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import ErrorPage from "./pages/ErrorPage"; // Страница ошибки
import AuthPage from "./pages/Auth"; // Страница авторизации/регистрации
import ListPage from "./pages/index"; // Страница списка объектов
import InsidePage from "./pages/InsidePage"; // Внутренняя страница объекта
import Middleware from "./components/Middleware"; // Промежуточное ПО
//@ts-ignore
import { useWindowSize } from "./store/useWindowSize.js";
/*
 * Обертка всего приложения
 */
export const App = (): ReactElement => {
  // Функция определения размера окна
  useWindowSize();
  // Возвращаем шаблон
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route element={<Middleware />}>
            <Route index element={<ListPage />} />

            <Route path="inside/:id" element={<InsidePage />} />
          </Route>

          <Route path="auth" element={<AuthPage />} />

          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
