import React, { ReactElement, memo } from "react";
/*
 * Объект стилей
 */
interface IProps {
  text: string;
  span?: string;
  center?: boolean;
}
/*
 * Объект стилей
 */
const styles = {
  row: {
    fontSize: "var(--fontSizeSmall)",
    color: "var(--black)",
    cursor: "default",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    paddingBottom: "var(--paddingExtraSmall)",
  },
  span: {
    fontSize: "var(--fontSizeSmall)",
    color: "var(--black)",
    paddingLeft: "var(--paddingSmall)",
  },
};
/*
 * Компонент Ellipsis строки
 */
const MyEllipsisRow = ({ text, span, center }: IProps): ReactElement => {
  // Возвращаем шаблон
  return (
    <p
      style={{
        ...styles.row,
        textAlign: center ? "center" : "left",
      }}
      title={`${text}: ${span}`}
    >
      {text}

      {span && (
        <>
          : <span style={styles.span}>{span}</span>
        </>
      )}
    </p>
  );
};
/*
 * Экспорт
 */
export default memo(MyEllipsisRow);
