import React, { useState, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import MyButton from "../MyButton";
import { LogoutOutlined } from "@ant-design/icons";
import {
  clearLocalstorage,
  getStatusLocalStorageData,
} from "../../helpers/localstorage";
import { clearCookies, getStatusCookie } from "../../helpers/cookie";
import DownloadBtn from "../Controls/DownloadBtn";
/*
 * Объект стилей
 */
const styles = {
  navbar: {
    width: "max-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "var(--gap)",
  },
};
/*
 * Компонент navbar в шапке сайта
 */
export default function (): ReactElement {
  // Состояние авторизованности
  const [isAuth, setIsAuth] = useState(true);
  // Флаг наличия нужных данных в localstorage
  const statusLocalstorage = getStatusLocalStorageData();
  // Флаг наличия нужных данных в cookie
  const statusCookie = getStatusCookie();
  // Функция для выхода из системы
  const logout = () => {
    clearLocalstorage();
    clearCookies();
    setIsAuth(false);
  };
  // Возвращаем шаблон компонента
  return isAuth && (statusLocalstorage || statusCookie) ? (
    <nav style={{ ...styles.navbar }}>
      <DownloadBtn title="Скачать БД" />

      <MyButton
        click={logout}
        alt="Выйти из аккаунта"
        icon={<LogoutOutlined />}
        red
      />
    </nav>
  ) : (
    <Navigate to="/auth" />
  );
}
