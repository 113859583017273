import React, { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getStatusLocalStorageData } from "../../helpers/localstorage";
import { getStatusCookie } from "../../helpers/cookie";
/*
 * Промежуточное ПО проверки
 */
export default function (): ReactElement {
  const statusLocalstorage = getStatusLocalStorageData();
  const statusCookie = getStatusCookie();

  if (statusLocalstorage || statusCookie) {
    return <Outlet />;
  } else {
    return <Navigate to="/auth" />;
  }
}
