import React, { useState, useEffect, useMemo } from "react";
import { Form } from "antd";
import { getBrands, getYears } from "../../../helpers";
import { myFetch } from "../../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../../helpers/notify";
import { IForm } from "../../../types/props";
import MySelect from "../../MySelect";
import MyInput from "../../MyInput";
import MyButton from "../../MyButton";
import MyFlex from "../../MyFlex";
/*
 * Компонент редактирования объекта
 */
export default function ({ getData, toClose, isData }: IForm) {
  // State с типом объекта
  const [type, setType] = useState("");
  // State с названием объекта
  const [name, setName] = useState("");
  // State с описанием объекта
  const [description, setDescription] = useState("");
  // State с маркой автомобиля
  const [brand, setBrand] = useState("");
  // State с годом производства автомобиля
  const [year, setYear] = useState("");
  // State с моделью автомобиля
  const [model, setModel] = useState("");
  // State с г/н автомобиля
  const [number, setNumber] = useState("");
  // State с статусом загрузки формы
  const [loading, setLoading] = useState(false);
  // Очистка всех полей
  const allClear = () => {
    setBrand("");
    setYear("");
    setModel("");
    setNumber("");
    setType("");
    setLoading(false);
  };
  // Действия при изменении isData
  useEffect(() => {
    const { brand, year, model, type, number } = isData;
    setBrand(brand);
    setYear(year);
    setModel(model);
    setType(type);
    setNumber(number);
  }, [isData]);
  // Определение активности кнопки для формы
  const isDisabled = useMemo(() => {
    return !brand || !year || !model || number.length < 8;
  }, [brand, year, model, number]);
  // Функция сохранения изменений объекта
  const isSave = async () => {
    setLoading(true);
    // Основная логика
    try {
      const { data, error } = await myFetch(
        isData?.id,
        {
          brand,
          model,
          year,
          number,
          type,
        },
        "PUT",
      );
      // Если пришла ошибка
      if (error) return showErrorNotify(error);
      // Показ успешного уведомления
      showSuccessNotify(data);
      // Запрос данных
      await getData();
      // Закрываем модалку
      toClose(false);
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Очистка всех полей
      allClear();
    }
  };
  // Возвращаем шаблон
  return (
    <Form style={{ minWidth: "20rem" }} onFinish={isSave}>
      <MyFlex vertical>
        {type === "object" && (
          <>
            <MyInput
              title={"Название"}
              value={name}
              onChange={setName}
              placeholder="Введите название"
            />

            <MyInput
              title={"Описание"}
              value={description}
              onChange={setDescription}
              placeholder="Введите описание"
            />
          </>
        )}

        {type === "car" && (
          <>
            <MySelect
              options={getBrands()}
              title={"Марка автомобиля"}
              placeholder="Выберите марку автомобиля"
              value={brand}
              onChange={setBrand}
            />

            <MyInput
              title={"Модель автомобиля"}
              value={model}
              onChange={setModel}
              placeholder="Введите модель автомобиля"
            />

            <MySelect
              options={getYears()}
              title={"Год производства автомобиля"}
              placeholder="Выберите год производства"
              value={year}
              onChange={setYear}
            />

            <MyInput
              title={"г/н автомобиля"}
              value={number}
              placeholder="Введите г/н автомобиля"
              onChange={setNumber}
              carNumber
            />
          </>
        )}

        <MyButton
          title="Сохранить"
          alt="Сохранить изменения"
          submit
          fullWidth
          loading={loading}
          disabled={isDisabled}
          primary
        />
      </MyFlex>
    </Form>
  );
}
