import React from "react";
import { Link } from "react-router-dom";
/*
 * Объект стилей
 */
const styles = {
  logo: {
    width: "max-content",
    display: "flex",
    alignItems: "center",
    gap: ".25rem",
  },
  logoBig: {
    fontSize: "1rem",
  },
  logoSmall: {
    fontSize: ".75rem",
  },
};
/*
 * Компонент лого
 */
export default function () {
  return (
    <Link
      to="/"
      style={{ ...styles.logo, textTransform: "uppercase" }}
      key="logo"
      title="RESALE crm"
    >
      <span style={styles.logoBig}>resale</span>

      <span style={styles.logoSmall}>crm</span>
    </Link>
  );
}
