import { desiredKeys } from "./index";
import { ICookie } from "../types/cookie";

export const setCookie = (
  key: string,
  val: string,
  maxAge: string = "3600",
): void => {
  document.cookie = `${key}=${val}; max-age=${maxAge}`;
};

export const getStatusCookie = (): boolean => {
  const cookies = document.cookie.replace(/ /g, "").split(";");
  const hasDesiredKeys = (cookies: Array<any>, desiredKeys: Array<any>) => {
    const cookiesObj = cookies.reduce((acc, cookie) => {
      const [key, value] = cookie.split("=");
      acc[key.trim()] = value;
      return acc;
    }, {});
    return desiredKeys.every((key) => key in cookiesObj);
  };

  return hasDesiredKeys(cookies, desiredKeys);
};

export const getCookie = (key: string): string | null => {
  const cookiesStr = document.cookie;
  const cookies = cookiesStr.split(";").reduce((acc, cookie) => {
    const [cookieKey, cookieValue] = cookie.split("=");
    acc[cookieKey.trim()] = cookieValue;
    return acc;
  }, {} as ICookie);

  if (key in cookies) {
    return cookies[key];
  }

  return null;
};

export const clearCookies = (): void => {
  desiredKeys.forEach((key) => (document.cookie = `${key}=; max-age=-1`));
};
