import React, { ReactElement } from "react";
import "./index.css"; // Импорт стилей
/*
 * Интерфейс входных данных
 */
interface IProps {
  title?: string;
  left?: boolean;
  right?: boolean;
  hovered?: boolean;
}
/*
 * Кастомный компонент select
 */
export default function ({
  title = "",
  left = false,
  right = false,
  hovered = false,
}: IProps): ReactElement {
  // Возвращаем шаблон
  return (
    <div className="my-divide__wrap">
      <div
        className={!left ? "my-divide__line" : "my-divide__line_small"}
        style={{ backgroundColor: hovered ? "var(--primary)" : "var(--gray)" }}
      />

      <span className="my-divide__text">{title}</span>

      <div
        className={!right ? "my-divide__line" : "my-divide__line_small"}
        style={{ backgroundColor: hovered ? "var(--primary)" : "var(--gray)" }}
      />
    </div>
  );
}
