import React, { memo, useState } from "react";
import { useParams } from "react-router-dom";
import { Upload, Empty, Row, Col } from "antd";
import {
  UploadOutlined,
  CloudUploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { uploadFiles } from "../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../helpers/notify";
import MyButton from "../MyButton";
import { IUpload } from "../../types/props";
import { IFile } from "../../types/base_object";
import Controls from "./ItemControls";
import RendererItem from "./RenderItem";
import MyFlex from "../MyFlex";
/*
 * Компонент загрузки и отображения файлов
 */
export const UploadImages = ({
  files = [],
  getData,
  itemid,
  single = false,
  toClose,
}: IUpload) => {
  // Инициализируем состояние списка файлов
  const [fileList, setFileList] = useState([]);
  // Инициализируем состояние загрузки
  const [loading, setLoading] = useState(false);
  // Получаем ID из параметров URL
  const { id } = useParams();
  // Устанавливаем допустимые типы файлов для загрузки
  const acceptFiles = single
    ? "image/*" // Если единственный файл, то только изображения
    : "video/*,image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf";
  // Функция отправки файлов на сервер
  const isUpload = async () => {
    // Отменяем попытку скачивания если оно уже идет
    if (loading) return;
    // Меняем состояние кнопки загрузки БД
    setLoading(true);
    // Основная логика
    try {
      // Создаем объект FormData
      const form = new FormData();
      // Добавляем каждый файл в FormData с помощью перебора
      fileList.forEach((file: any) => {
        // Добавляем каждый файл в FormData
        form.append("file", file.originFileObj);
      });
      // Отправляем форму на сервер и получаем ответ
      const { data, error } = await uploadFiles(
        form,
        id || "",
        itemid || "",
        single,
      );
      // Если есть ошибка, показываем уведомление об ошибке
      if (error) return showErrorNotify(error);
      // Если режим одиночной загрузки и есть функция закрытия, закрываем
      if (single && typeof toClose === "function") toClose();
      // Функция получения данных
      else await getData();
      // Показываем уведомление об успешной загрузке
      showSuccessNotify(data);
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Очищаем список файлов
      setFileList([]);
      // Сбрасываем состояние кнопки загрузки
      setLoading(false);
    }
  };
  // Шаблон компонента
  return (
    <MyFlex vertical width="100%">
      {!fileList.length && !files.length && (
        <Empty
          style={{
            minWidth: "100%",
            margin: 0,
          }}
        />
      )}

      {!fileList.length && !!files.length && (
        <Row gutter={[8, 8]}>
          {!!files.length &&
            files.reverse().map((file: IFile, i: number) => {
              return (
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  key={i}
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    flexDirection: "column",
                  }}
                >
                  <RendererItem file={file} single={single} />

                  <Controls
                    id={id}
                    itemid={itemid}
                    getData={getData}
                    file={file}
                  />
                </Col>
              );
            })}
        </Row>
      )}

      <Upload
        maxCount={single ? 1 : 5}
        multiple={single ? false : true}
        fileList={fileList}
        accept={acceptFiles}
        onChange={({ fileList: newFileList }: any) => setFileList(newFileList)}
        beforeUpload={() => false}
      >
        {!fileList?.length && (
          <MyButton
            icon={<UploadOutlined />}
            alt="Добавить файл"
            fullWidth
            primary
          />
        )}
      </Upload>

      {!!fileList?.length && (
        <MyFlex vertical>
          {!single && (
            <MyButton
              click={() => setFileList([])}
              icon={<CloseOutlined />}
              alt="Очистить выбор"
              red
              fullWidth
            />
          )}

          <MyButton
            click={() => isUpload()}
            loading={loading}
            icon={<CloudUploadOutlined />}
            alt="Загрузить на сервер"
            primary
            fullWidth
          />
        </MyFlex>
      )}
    </MyFlex>
  );
};
/*
 * Экспорт
 */
export default memo(UploadImages);
