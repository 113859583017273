import React, { ReactElement } from "react";
import MyFlex from "../MyFlex";
import HeaderMain from "../MyHeader";
import { Outlet } from "react-router-dom";
/*
 * Объект стилей
 */
const styles = {
  wrap: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    gap: "var(--gap)",
    minHeight: "100vh",
    paddingBottom: "var(--paddingSmall)",
  },
};
/*
 * Компонент глобального layout
 */
export default function (): ReactElement {
  // Возвращаем шаблон
  return (
    <div style={styles.wrap}>
      <HeaderMain />

      <main className="container">
        <MyFlex vertical height="100%">
          <Outlet />
        </MyFlex>
      </main>
    </div>
  );
}
