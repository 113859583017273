import { combineReducers } from "redux";
import { breakpoints } from "./breakpoints";
import { CHANGE_VIEW_MODE, SET_WINDOW_WIDTH } from "./actions";

const initViewState = localStorage.getItem("mode") || "grid";

const view = (state = initViewState, action) => {
  switch (action.type) {
    case CHANGE_VIEW_MODE:
      return action.payload;
    default:
      return state;
  }
};

const initialState = {
  width: window.innerWidth,
};

const windowSize = (state = initialState, action) => {
  switch (action.type) {
    case SET_WINDOW_WIDTH:
      return {
        ...state,
        width: action.payload,
      };
    default:
      return state;
  }
};

const root = combineReducers({
  breakpoints,
  view,
  windowSize,
});

export default root;
